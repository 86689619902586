<template>
    <v-card>
        <v-card-title>Groupes :</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" v-for="group in groups" :key="group.id">
                    <v-card outlined>
                        <v-row class="mt-2 mb-0 mx-3">
                            <h3 v-text="group.name"></h3>
                            <v-spacer></v-spacer>
                            <v-btn icon>
                                <v-icon @click="open(group)">mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn icon color="error">
                                <v-icon @click="deleteOne(group)">mdi-delete-outline</v-icon>
                            </v-btn>
                        </v-row>
                        <v-row class="ma-0">
                            <v-card class="d-flex align-center ma-2" v-for="(member, i) in group.members" :key="i">
                                <UserAvatar :profile="member"></UserAvatar>
                                <b class="ma-0 mx-3" v-text="member.lastname + ' ' + member.firstname"></b>
                            </v-card>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-btn color="primary" fab @click="groupData.modal = true" class="mt-3">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-text>

        <v-dialog v-model="groupData.modal" max-width="1000px" persistent>
            <v-card class="pa-5">
                <h4>Nommer le groupe :</h4>
                <v-text-field v-model="groupData.name" type="text" label="Nom" clearable class="mt-3 mb-2 pt-0" />
                
                <h4>Membres du groupe :</h4>
                <v-row class="mt-1 mb-3">
                    <v-col cols="12" sm="6" md="4" v-for="(member, index) in groupData.members" :key="index">
                        <v-card outlined class="d-flex align-center pa-3 back-darker-onhover" @click="groupData.members.splice(index, 1)">
                            <UserAvatar :profile="member"></UserAvatar>
                            <b class="ma-0 mx-3" v-text="member.lastname + ' ' + member.firstname"></b>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="d-flex align-center">
                        <v-btn color="primary" small fab @click="$refs.dialogGetMembers.open()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                
                <div width="100%" class="d-flex justify-center">
                    <v-btn color="primary" @click="close" outlined class="mr-5">
                        <v-icon class="mr-2">mdi-minus</v-icon>Annuler
                    </v-btn>
                    <v-btn color="primary" @click="save" outlined>
                        <v-icon class="mr-2">mdi-plus</v-icon>Valider
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <DialogNewConversation with="users multipleSelection" :blacklist="[]" :selected="groupData.members.map(x => x.id)" @convSelected="(m) => groupData.members = m" ref="dialogGetMembers" :profile="profile"></DialogNewConversation>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import logger from "@/assets/functions/logger"

// classes
import Group from '@/classes/Group'
import Profile from "@/classes/Profile"

// components
import DialogNewConversation from '@/components/Communication/DialogNewConversation.vue'
import UserAvatar from "@/components/UserAvatar.vue"

export default {
    props: ["profile"],
    components: { DialogNewConversation, UserAvatar },
    data: () => ({
        users: [],
        groups: [],
        groupData: {
            modal: false,
            id: null,
            name: '',
            oldName: null,
            members: []
        },
        unsub: []
    }),
    methods: {
        close() {
            this.groupData = {
                modal: false,
                id: null,
                name: '',
                oldName: null,
                members: []
            }
        },
        open(group) {
            // need for "unlink" the modal data and group data when edit
            let tmp_members = []
            for (let m of group.members) tmp_members.push(m)

            this.groupData = {
                modal: true,
                id: group.id,
                name: group.name,
                oldName: group.name,
                members: tmp_members
            }
        },
        save() {
            let text = ''

            if (this.groupData.name.trim() != '') {
                if (!this.groups.map(x => x.name).includes(this.groupData.name) || this.groupData.name.trim() === this.groupData.oldName) {
                    if (this.groupData.members.length > 1) {

                        // all good
                        new Group(
                            this.groupData.id,
                            this.profile.currentRoom,
                            this.groupData.name.trim(),
                            this.groupData.members.map(x => x.id)
                        ).save().then(() => {
                            logger.log(this.profile.id, 'GROUP ADD', `${this.profile.email} a ajouté un groupe`)
                            this.close()
                            Swal.fire({
                                title: 'Croupe créé',
                                text: 'Le groupe a bien été créé',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            })
                            this.sortGroups()
                        })
                        return

                    } else text = 'Groupe incomplet. Au moins 2 membres sont nécessaires.'
                } else text = 'Nom déjà utilisé.'
            } else text = 'Groupe incomplet. Un nom est requis.'

            Swal.fire({
                title: 'Erreur',
                text: text,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        },
        deleteOne(group) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if(result.isConfirmed){
                    group.delete().then(() => {
                        logger.log(this.profile.id, "GROUP DELETED", `${this.profile.email} a supprimé le group ${group.id}`)
                        Swal.fire({
                            title: 'Croupe supprimé',
                            text: 'Le groupe a bien été supprimé',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            })
        },
        sortGroups() {
            this.groups.sort((a, b) => {
                if (a.name > b.name)
                    return 1
                else if (b.name > a.name)
                    return -1
                else
                    return 0
            })
        }
    },
    created() {
        this.unsub.push(Group.listenByRoom(this.profile.currentRoom, async data => {
            let tmp_groups = []
            for (let group of data) {
                let tmp_members = []
                for (let memberId of group.members) {

                    // only 1 request for each user
                    // each user is store in this.users[userId]
                    let tmp_member
                    if (this.users[memberId]) {
                        tmp_member = this.users[memberId]
                    } else {
                        tmp_member = await Profile.getById(memberId)
                        this.users[memberId] = tmp_member
                    }
                    tmp_members.push(tmp_member)
                }
                tmp_groups.push(new Group(
                    group.id,
                    group.room,
                    group.name,
                    tmp_members
                ))
            }
            this.groups = tmp_groups
            this.sortGroups()
        }))
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub());
    }
}
</script>

<style>
.back-darker-onhover {
    border: solid 1px;
    border-radius: 10px;
}
.back-darker-onhover:hover {
    background-color: #0002;
}
</style>